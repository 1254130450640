var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("main", { staticClass: "site-content", attrs: { id: "wrapper" } }, [
    _c("div", { staticClass: "site-content__inner" }, [
      _c("div", { staticClass: "site-content__holder" }, [
        _vm._m(0),
        _c("h1", { staticClass: "page-title h3" }, [_vm._v("About Us")]),
        _c("div", { staticClass: "page-content" }, [
          _c("p", [
            _vm._v(
              " Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim en veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip. "
            )
          ]),
          _c("div", { staticClass: "spacer" }),
          _vm._m(1),
          _c("div", { staticClass: "spacer" }),
          _c("h4", [_vm._v("Why Necromancers?")]),
          _c("div", { staticClass: "spacer" }),
          _c("p", [
            _vm._v(
              " Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo lorem inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur. "
            )
          ]),
          _c("div", { staticClass: "spacer" }),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-4 mb-4 mb-md-0" }, [
              _c("div", { staticClass: "counter" }, [
                _c(
                  "div",
                  { staticClass: "counter__icon counter__icon--left" },
                  [
                    _c(
                      "svg",
                      {
                        staticClass: "df-icon df-icon--joystick",
                        attrs: { role: "img" }
                      },
                      [
                        _c("use", {
                          attrs: {
                            "xlink:href": "assets/img/necromancers.svg#joystick"
                          }
                        })
                      ]
                    )
                  ]
                ),
                _c("div", { staticClass: "counter__number" }, [_vm._v("979")]),
                _c("div", { staticClass: "counter__label" }, [
                  _vm._v("Matches")
                ])
              ])
            ]),
            _c("div", { staticClass: "col-md-4 mb-4 mb-md-0" }, [
              _c("div", { staticClass: "counter" }, [
                _c(
                  "div",
                  { staticClass: "counter__icon counter__icon--left" },
                  [
                    _c(
                      "svg",
                      {
                        staticClass: "df-icon df-icon--trophy",
                        attrs: { role: "img" }
                      },
                      [
                        _c("use", {
                          attrs: {
                            "xlink:href": "assets/img/necromancers.svg#trophy"
                          }
                        })
                      ]
                    )
                  ]
                ),
                _c("div", { staticClass: "counter__number" }, [_vm._v("34")]),
                _c("div", { staticClass: "counter__label" }, [
                  _vm._v("Trophies")
                ])
              ])
            ]),
            _c("div", { staticClass: "col-md-4" }, [
              _c("div", { staticClass: "counter" }, [
                _c(
                  "div",
                  { staticClass: "counter__icon counter__icon--left" },
                  [
                    _c(
                      "svg",
                      {
                        staticClass: "df-icon df-icon--chart",
                        attrs: { role: "img" }
                      },
                      [
                        _c("use", {
                          attrs: {
                            "xlink:href": "assets/img/necromancers.svg#chart"
                          }
                        })
                      ]
                    )
                  ]
                ),
                _c("div", { staticClass: "counter__number" }, [_vm._v("82.1")]),
                _c("div", { staticClass: "counter__label" }, [
                  _vm._v("Win Ratio")
                ])
              ])
            ])
          ]),
          _c("div", { staticClass: "spacer" }),
          _vm._m(2)
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "figure",
      {
        staticClass: "page-thumbnail page-thumbnail--default",
        staticStyle: { top: "0px" }
      },
      [
        _c("img", {
          staticClass: "page-bg-logo",
          attrs: { src: require("@/assets/img/logo.png"), alt: "" }
        }),
        _c("div", { staticClass: "ncr-page-decor" }, [
          _c("div", { staticClass: "ncr-page-decor__layer-1" }, [
            _c("div", { staticClass: "ncr-page-decor__layer-bg" })
          ]),
          _c("div", { staticClass: "ncr-page-decor__layer-2" }),
          _c("div", { staticClass: "ncr-page-decor__layer-3" }, [
            _c("div", { staticClass: "ncr-page-decor__layer-bg" })
          ]),
          _c("div", { staticClass: "ncr-page-decor__layer-4" }),
          _c("div", { staticClass: "ncr-page-decor__layer-5" }),
          _c("div", { staticClass: "ncr-page-decor__layer-6" })
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "world-map" }, [
      _c(
        "div",
        {
          staticClass: "world-map-team world-map-team--left world-map-team--one"
        },
        [
          _c("figure", { attrs: { role: "group" } }, [
            _c(
              "figure",
              { staticClass: "world-map-team__logo", attrs: { role: "group" } },
              [
                _c("img", {
                  attrs: {
                    src: require("@/assets/img/logo.png"),
                    srcset: require("@/assets/img/logo@2x.png") + " 2x",
                    alt: "Combet Logo"
                  }
                })
              ]
            ),
            _c("figcaption", [
              _c("div", { staticClass: "world-map-team__name" }, [
                _vm._v("Necromancers")
              ]),
              _c("div", { staticClass: "world-map-team__country" }, [
                _vm._v("United States")
              ])
            ])
          ]),
          _c("div", { staticClass: "world-map-team__anchor" })
        ]
      ),
      _c(
        "div",
        {
          staticClass:
            "world-map-team world-map-team--right world-map-team--two"
        },
        [
          _c("figure", { attrs: { role: "group" } }, [
            _c(
              "figure",
              { staticClass: "world-map-team__logo", attrs: { role: "group" } },
              [
                _c("img", {
                  attrs: {
                    src: require("@/assets/img/logo.png"),
                    srcset: require("@/assets/img/logo@2x.png") + " 2x",
                    alt: "Necromancers Logo"
                  }
                })
              ]
            ),
            _c("figcaption", [
              _c("div", { staticClass: "world-map-team__name" }, [
                _vm._v("Necromancers")
              ]),
              _c("div", { staticClass: "world-map-team__country" }, [
                _vm._v("Australia (Soon!)")
              ])
            ])
          ]),
          _c("div", { staticClass: "world-map-team__anchor" })
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("figure", { staticClass: "lightbox lightbox--video" }, [
      _c(
        "a",
        {
          staticClass: "mp_iframe",
          attrs: { href: "https://www.youtube.com/watch?v&#x3D;XE0fU9PCrWE" }
        },
        [
          _c("img", {
            attrs: {
              src: "assets/img/samples/features-about-us-img-01.jpg",
              alt: ""
            }
          }),
          _c("span", { staticClass: "lightbox__icon" })
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }