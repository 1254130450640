<template>
  <main class="site-content" id="wrapper">
			<div class="site-content__inner">
				<div class="site-content__holder">
					<figure class="page-thumbnail page-thumbnail--default" style="top: 0px">
						<img class="page-bg-logo" src="@/assets/img/logo.png" alt="">
						<!-- Decoration -->
						<div class="ncr-page-decor">
							<div class="ncr-page-decor__layer-1">
								<div class="ncr-page-decor__layer-bg"></div>
							</div>
							<div class="ncr-page-decor__layer-2"></div>
							<div class="ncr-page-decor__layer-3">
								<div class="ncr-page-decor__layer-bg"></div>
							</div>
							<div class="ncr-page-decor__layer-4"></div>
							<div class="ncr-page-decor__layer-5"></div>
							<div class="ncr-page-decor__layer-6"></div>
						</div>
						<!-- Decoration / End -->
					</figure>
					<h1 class="page-title h3">About Us</h1>
					<div class="page-content">
						<p>
							Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim en veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.
						</p>
						<div class="spacer"></div>
						<div class="world-map">
							<div class="world-map-team world-map-team--left world-map-team--one">
								<figure role="group">
									<figure class="world-map-team__logo" role="group">
										<img src="@/assets/img/logo.png" srcset="@/assets/img/logo@2x.png 2x" alt="Combet Logo">
									</figure>
									<figcaption>
										<div class="world-map-team__name">Necromancers</div>
											<div class="world-map-team__country">United States</div>
									</figcaption>
								</figure>
								<div class="world-map-team__anchor"></div>
							</div>
							<div class="world-map-team world-map-team--right world-map-team--two">
								<figure role="group">
									<figure class="world-map-team__logo" role="group">
										<img src="@/assets/img/logo.png" srcset="@/assets/img/logo@2x.png 2x" alt="Necromancers Logo">
									</figure>
									<figcaption>
										<div class="world-map-team__name">Necromancers</div>
											<div class="world-map-team__country">Australia (Soon!)</div>
									</figcaption>
								</figure>
								<div class="world-map-team__anchor"></div>
							</div>
						</div>
						<div class="spacer"></div>
						<h4>Why Necromancers?</h4>
						<div class="spacer"></div>
						<p>
							Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo lorem inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur.
						</p>
						<div class="spacer"></div>
						<div class="row">
							<div class="col-md-4 mb-4 mb-md-0">
								<div class="counter">
									<div class="counter__icon counter__icon--left">
										<svg role="img" class="df-icon df-icon--joystick">
											<use xlink:href="assets/img/necromancers.svg#joystick"/>
										</svg>
									</div>
									<div class="counter__number">979</div>
									<div class="counter__label">Matches</div>
								</div>
							</div>
							<div class="col-md-4 mb-4 mb-md-0">
								<div class="counter">
									<div class="counter__icon counter__icon--left">
										<svg role="img" class="df-icon df-icon--trophy">
											<use xlink:href="assets/img/necromancers.svg#trophy"/>
										</svg>
									</div>
									<div class="counter__number">34</div>
									<div class="counter__label">Trophies</div>
								</div>
							</div>
							<div class="col-md-4">
								<div class="counter">
									<div class="counter__icon counter__icon--left">
										<svg role="img" class="df-icon df-icon--chart">
											<use xlink:href="assets/img/necromancers.svg#chart"/>
										</svg>
									</div>
									<div class="counter__number">82.1</div>
									<div class="counter__label">Win Ratio</div>
								</div>
							</div>
						</div>
						<div class="spacer"></div>
						<figure class="lightbox lightbox--video">
							<a href="https://www.youtube.com/watch?v&#x3D;XE0fU9PCrWE" class="mp_iframe">
								<img src="assets/img/samples/features-about-us-img-01.jpg" alt="">
								<span class="lightbox__icon"><!----></span>
							</a>
						</figure>
					</div>
				</div>
			</div>
		</main>
</template>

<script>
export default {

};
</script>

<style>

</style>
